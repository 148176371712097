import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class HPEStaticService {
    public service: any;
    constructor(
        private datepipe: DatePipe,
    ) { }

    getFieldbyFiledId(filedId) {
        var HPEFieldsOnForm = [
            //system field
            { filedId: 1, labelName: "Title", searchClauseName: "typedTitle", customPropertyName: "Title", IsCustomerFiled: false, FieldType: "string", IsDisabled: true, IsRequired: false, value: "", isValidation: true, isShow: false, isRead: true ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed"},
            { filedId: 2, labelName: "Agreement ID", searchClauseName: "number", customPropertyName: "Number", IsCustomerFiled: false, FieldType: "string", IsDisabled: true, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed"},
           
           //custom properties
            { filedId: 3, labelName: "Penalty exceeds 1M USD", searchClauseName: "USDExceeded", customPropertyName: "USD Exceeded", IsCustomerFiled: true, FieldType: "radio", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false, information:"Is there a penalty clause, were Accenture to “terminate the contract”, that exceeds USD 1M?", infoArrow:"left"},
            { filedId: 4, labelName: "Additional Information", searchClauseName: "AdditionalInformation", customPropertyName: "Additional Information", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 10000},
            { filedId: 5, labelName: "Agreement Complexity", searchClauseName: "AgreementComplexity", customPropertyName: "Agreement Complexity", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 255},
            { filedId: 6, labelName: "Agreement End Date", searchClauseName: "AgreementEndDate", customPropertyName: "Agreement End Date", IsCustomerFiled: true, FieldType: "date", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 7, labelName: "Agreement Terms and Conditions", searchClauseName: "AgreementPaper", customPropertyName: "Agreement Paper", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 255},
            { filedId: 8, labelName: "Supplier Name", searchClauseName: "SupplierName", customPropertyName: "Supplier Name", IsCustomerFiled: true, FieldType: "string", IsDisabled: true, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed"},
            { filedId: 9, labelName: "Key Category", searchClauseName: "KeyCategory", customPropertyName: "Key Category", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 10, labelName: "Category", searchClauseName: "CategoryVal", customPropertyName: "CategoryVal", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: true, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 11, labelName: "Agreement Type", searchClauseName: "AgreementType", customPropertyName: "Agreement Type", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 12, labelName: "Country/Location", searchClauseName: "PrimaryCountry", customPropertyName: "Primary Country", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 13, labelName: "Document Owner", searchClauseName: "DocumentOwner", customPropertyName: "Document Owner", IsCustomerFiled: true, FieldType: "string", IsDisabled: true, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed"},
            { filedId: 14, labelName: "Agreement Status", searchClauseName: "AgreementStatus", customPropertyName: "Agreement Status", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: true, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 15, labelName: "Agreement Start Date", searchClauseName: "AgreementStartDate", customPropertyName: "Agreement Start Date", IsCustomerFiled: true, FieldType: "date", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 16, labelName: "Supplier SAP ID", searchClauseName: "SupplierID", customPropertyName: "Supplier SAP ID", IsCustomerFiled: true, FieldType: "string", IsDisabled: true, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed"},
            { filedId: 17, labelName: "Date Last Modified", searchClauseName: "editedOn", customPropertyName: "Date Modified", IsCustomerFiled: false, FieldType: "dateTime", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false, oldValue: "", toolTip: false, selectTip: false },
          
            { filedId: 18, labelName: "Company Code", searchClauseName: "CompanyCode", customPropertyName: "Company Code", IsCustomerFiled: true, FieldType: "string", IsDisabled: true, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed"},
            { filedId: 19, labelName: "Supplier Name", searchClauseName: "typedTitle", customPropertyName: "Title", IsCustomerFiled: false, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false, oldValue: "", toolTip: false, selectTip: false, placeholder: "Field must be completed", maxlength: 50 },
            { filedId: 20, labelName: "Agreement Value", searchClauseName: "AgreementValue", customPropertyName: "Agreement Value", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false, oldValue: "", toolTip: false, selectTip: false, placeholder: "Field must be completed", maxlength: 255 },
            { filedId: 21, labelName: "Expiration Date", searchClauseName: "ExpirationDate", customPropertyName: "Expiration Date", IsCustomerFiled: true, FieldType: "date", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 22, labelName: "File Name", searchClauseName: "FileName", customPropertyName: "File Name", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 255},
            { filedId: 23, labelName: "GDPR", searchClauseName: "GDPR", customPropertyName: "GDPR", IsCustomerFiled: true, FieldType: "radio", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false, information:"The GDPR, General Data Protection Regulation, is designed to unify data privacy laws across Europe and to protect and strengthen data privacy within the EU. It places obligations directly on data controllers and data processors."},
            { filedId: 24, labelName: "Data Privacy & Info sec", searchClauseName: "InfoSec", customPropertyName: "Info Sec", IsCustomerFiled: true, FieldType: "radio", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false, information:"Has the sponsor/stakeholder completed the data privacy/info sec questionnaire?"},
            { filedId: 25, labelName: "Language", searchClauseName: "Language", customPropertyName: "Language", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 26, labelName: "Legal Department Contact", searchClauseName: "LegalDepartmentContact", customPropertyName: "Legal Department Contact", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 100},
            { filedId: 27, labelName: "License", searchClauseName: "License", customPropertyName: "License", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 28, labelName: "License Type", searchClauseName: "LicenseType", customPropertyName: "License Type", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 29, labelName: "Market Intelligence", searchClauseName: "MarketIntelligence", customPropertyName: "Market Intelligence", IsCustomerFiled: true, FieldType: "radio", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false, information:"Accenture may use data and information including, but not limited to, Supplier's pricing and other information provided to Accenture in relation to or as part of the Agreement, in providing services to its clients and to develop and create reports, statistical analysis, and benchmarking analysis (Market Reports) for its clients provided that such Market Reports contain only anonymous, aggregated data and do not identify Supplier by name.", infoArrow:"right"},
            { filedId: 30, labelName: "Minimum Spend Commitment", searchClauseName: "MinimumSpendCommitment", customPropertyName: "Minimum Spend Commitment", IsCustomerFiled: true, FieldType: "radio", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 31, labelName: "Other Language", searchClauseName: "OtherLanguage", customPropertyName: "Other Language", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 255},
            { filedId: 32, labelName: "Parent Record", searchClauseName: "ParentDocument", customPropertyName: "Parent Record", IsCustomerFiled: true, FieldType: "string", IsDisabled: true, IsRequired: false, value: "", isValidation: true, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed"},
            { filedId: 33, labelName: "Parent or Supporting Record", searchClauseName: "ParentOrSupportingDocument", customPropertyName: "Parent or Supporting Record", IsCustomerFiled: true, FieldType: "radio", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 34, labelName: "Payment Terms", searchClauseName: "PaymentTerms", customPropertyName: "Payment Terms", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 35, labelName: "Predecessor Agreement ID", searchClauseName: "PredecessorAgreementID", customPropertyName: "Predecessor Agreement ID", IsCustomerFiled: true, FieldType: "string", IsDisabled: true, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,isError: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed", information:"The ID of a record that is followed by this new one as part of the same contract activity.  E.g. SOW #3 in a sequence is preceded by SOW #2."},
            { filedId: 36, labelName: "Primary MU", searchClauseName: "PrimaryGU", customPropertyName: "Primary GU", IsCustomerFiled: true, FieldType: "string", IsDisabled: true, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed"},
            { filedId: 37, labelName: "Primary Market", searchClauseName: "PrimaryRegion", customPropertyName: "Primary Region", IsCustomerFiled: true, FieldType: "string", IsDisabled: true, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed"},
            { filedId: 38, labelName: "Record Status", searchClauseName: "RecordStatus", customPropertyName: "Record Status", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 39, labelName: "Renewal/Review Date", searchClauseName: "RenewalDate", customPropertyName: "Renewal Date", IsCustomerFiled: true, FieldType: "date", IsDisabled: true, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 40, labelName: "Renewal/Review Notice (days)", searchClauseName: "RenewalNotice", customPropertyName: "Renewal Notice", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "120", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 50},
            { filedId: 41, labelName: "Renewal Type", searchClauseName: "RenewalType", customPropertyName: "Renewal Type", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 42, labelName: "Secondary Country", searchClauseName: "SecondaryCountryIes", customPropertyName: "Secondary Country(ies)", IsCustomerFiled: true, FieldType: "mutiSelect", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 43, labelName: "Business Contract Owner", searchClauseName: "StakeholderOwner", customPropertyName: "Stakeholder Owner", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false,oldValue:"",toolTip: false,selectTip:false ,placeholder:"Field must be completed",maxlength: 255, information:"The Contract Owner is the business stakeholder (typically MD) responsible for the entity that is sponsoring the use of the supplier. The Contract Owner may designate someone (typically a Manager or above) in the entity to take on the responsibilities – but not accountability."},
            { filedId: 44, labelName: "Subcategory", searchClauseName: "SubCategory", customPropertyName: "SubCategory", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: true, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 45, labelName: "Supliper Code of Conduct", searchClauseName: "SupplierCodeOfConduct", customPropertyName: "Supplier Code of Conduct", IsCustomerFiled: true, FieldType: "radio", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false, information: "If your answer is No, you must make Procurement + Risk and Sustainability Team aware -" + sessionStorage.getItem("emailContact") , infoArrow:"right"},
            { filedId: 46, labelName: "Termination For Convenience", searchClauseName: "TerminationForConvenience", customPropertyName: "Termination For Convenience", IsCustomerFiled: true, FieldType: "radio", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 47, labelName: "Termination notice", searchClauseName: "TerminationNotice", customPropertyName: "Termination notice", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 50},
            { filedId: 48, labelName: "Container", searchClauseName: "Container", customPropertyName: "Container", IsCustomerFiled: false, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false, oldValue: "", toolTip: false, selectTip: false, placeholder: "Field must be completed" },
            { filedId: 50, labelName: "Submitted By", searchClauseName: "Creator", customPropertyName: "Creator", IsCustomerFiled: false, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false, oldValue: "", toolTip: false, selectTip: false, placeholder: "Field must be completed" },
            { filedId: 51, labelName: "Submitted Date", searchClauseName: "createdOn", customPropertyName: "createdOn", IsCustomerFiled: false, FieldType: "dateTime", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false, oldValue: "", toolTip: false, selectTip: false },
            { filedId: 52, labelName: "Assignee", searchClauseName: "assignee", customPropertyName: "assignee", IsCustomerFiled: false, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false, oldValue: "", toolTip: false, selectTip: false, placeholder: "Field must be completed" },
          
            { filedId: 53, labelName: "Submission Status", searchClauseName: "RecordStatus", customPropertyName: "Record Status", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false, oldValue: "", toolTip: false, selectTip: false },
            { filedId: 54, labelName: "Second Country", searchClauseName: "SecondCountry", customPropertyName: "Second Country", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 65536},
             //custom properties - Reject Reasons--New
             { filedId: 55, labelName: "Incorrect metadata fields", searchClauseName: "IncorrectMetadataField", customPropertyName: "Incorrect metadata field", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
             { filedId: 56, labelName: "Incorrect metadata Reason", searchClauseName: "IncorrectMetadataReason", customPropertyName: "Incorrect metadata Reason", IsCustomerFiled: true, FieldType: "text", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,maxlength: 65536},
             { filedId: 57, labelName: "Incomplete agreement", searchClauseName: "IncompleteAgreement", customPropertyName: "Incomplete agreement", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
             { filedId: 58, labelName: "Incorrect agreement attached", searchClauseName: "IncorrectAgreementAttached", customPropertyName: "Incorrect agreement attached", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
             { filedId: 59, labelName: "Agreement attached not signed/finalized", searchClauseName: "AgreementAttachedNotSignedfinalized", customPropertyName: "Agreement attached not signedfinalized", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
             { filedId: 60, labelName: "Other", searchClauseName: "Other", customPropertyName: "Other", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
             { filedId: 61, labelName: "Other Reason", searchClauseName: "OtherReason", customPropertyName: "Other Reason", IsCustomerFiled: true, FieldType: "text", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,maxlength: 65536},
            { filedId: 62, labelName: "Only NotifyOther", searchClauseName: "OtherAgreementType", customPropertyName: "Other Agreement Type", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 255},
            { filedId: 63, labelName: "Key Supplier", searchClauseName: "KeySupplier", customPropertyName: "Key Supplier", IsCustomerFiled: true, FieldType: "radio", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false, information:"A supplier that has been designated by Procurement Plus as vital to ongoing operations and receives additional P+ supplier management.   Currently this is not in use."},
            { filedId: 64, labelName: "Secondary Additional Information", searchClauseName: "SecondaryAdditionalInformation", customPropertyName: "Secondary Additional Information", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 10000},
            { filedId: 65, labelName: "Supplier Uri", searchClauseName: "SupplierUri", customPropertyName: "SupplierUri", IsCustomerFiled: true, FieldType: "number", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 66, labelName: "Agreement Name", searchClauseName: "AgreementName", customPropertyName: "Agreement Name", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength:255, information:"Category-Agreement Type-First 3 letters of Supplier Name-MMYY"},
            { filedId: 67, labelName: "Submitted Date", searchClauseName: "SubmitDates", customPropertyName: "SubmitDates", IsCustomerFiled: true, FieldType: "date", IsDisabled: false, IsRequired: false, value: "", isValidation: false, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 68, labelName: "Requestor", searchClauseName: "ExtensionRequestor", customPropertyName: "Extension Requestor", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,placeholder:"Field must be completed",maxlength:50},
            { filedId: 69, labelName: "Supplier Country", searchClauseName: "SupplierCountry", customPropertyName: "Supplier Country", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: false, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,placeholder:"",maxlength:50},
            //supplier search
            // { filedId: 70, labelName: "CompanyCodeOwnedByCd", searchClauseName: "CompanyCodeOwnedByCd", customPropertyName: "CompanyCodeOwnedByCd", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:getMatIconFailedToSanitizeError},
            // { filedId: 71, labelName: "CountryKey", searchClauseName: "CountryKey", customPropertyName: "CountryKey", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:getMatIconFailedToSanitizeError},
            { filedId: 70, labelName: "CompanyCodeOwnedByCd", searchClauseName: "CompanyCodeOwnedByCd", customPropertyName: "CompanyCodeOwnedByCd", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 71, labelName: "CountryKey", searchClauseName: "CountryKey", customPropertyName: "CountryKey", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            // delete request - Reject Reasons
            { filedId: 72, labelName: "Record already submitted previously Incorrect record", searchClauseName: "AlreadySubmittedIncorrect", customPropertyName: "Already submitted Incorrect", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},            
            { filedId: 73, labelName: "Incorrect attachment", searchClauseName: "IncorrectAttachment", customPropertyName: "Incorrect attachment", IsCustomerFiled: true, FieldType: "text", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,maxlength: 65536},
            { filedId: 74, labelName: "Record cannot be opened and may be corrupt", searchClauseName: "CannotBeOpened", customPropertyName: "Cannot be opened", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 75, labelName: "Record is outdated and can be archived", searchClauseName: "OutdatedAndCanBeArchived", customPropertyName: "Outdated and can be archived", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 76, labelName: "Other", searchClauseName: "OtherForDelete", customPropertyName: "Other For Delete", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 77, labelName: "Other Reason For Delete", searchClauseName: "OtherReasonForDelete", customPropertyName: "Other Reason For Delete", IsCustomerFiled: true, FieldType: "text", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,maxlength: 65536},
            { filedId: 78, labelName: "Incorrect record", searchClauseName: "IncorrectRecord", customPropertyName: "Incorrect record", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 79, labelName: "Termination Date", searchClauseName: "TerminationDate", customPropertyName: "Termination Date", IsCustomerFiled: true, FieldType: "date", IsDisabled: false, IsRequired: false, value: "", isValidation: false, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 80, labelName: "Record Submitter", searchClauseName: "RecordSubmitter", customPropertyName: "Record Submitter", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: false, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,placeholder:"",maxlength:50},
            { filedId: 81, labelName: "Termination Requestor", searchClauseName: "TerminationRequestor", customPropertyName: "Termination Requestor", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: false, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,placeholder:"",maxlength:50},
            { filedId: 82, labelName: "Is Auto Generated", searchClauseName: "IsAutoGenerated", customPropertyName: "Is Auto Generated", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: false, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            //user access request
            { filedId: 83, labelName: "Accesslevel", searchClauseName: "AccessLevel", customPropertyName: "AccessLevel", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 84, labelName: "Secondary Document Owner", searchClauseName: "SecondaryDocumentOwner", customPropertyName: "Secondary DocumentOwner", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 85, labelName: "Template Name", searchClauseName: "TemplateName", customPropertyName: "Template Name", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false},
            { filedId: 86, labelName: "Secondary Key Category", searchClauseName: "SecondaryKeyCategory", customPropertyName: "Secondary KeyCategory", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false, oldValue: "", toolTip: false, selectTip: false },
            { filedId: 87, labelName: "Agreement Value", searchClauseName: "AgreementValue", customPropertyName: "Agreement Value", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false, oldValue: "", toolTip: false, selectTip: false, placeholder:"USD" },
            { filedId: 88, labelName: "Reason for Payment Term is None of above please specify", searchClauseName: "ReasonForPaymentType", customPropertyName: "Reason For Payment Type", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 200},
            //custom properties - Approve Reasons -- New
            { filedId: 89, labelName: "‘Awaiting SAP ID’ was selected but we found the SAP ID as per supplier name and company code", searchClauseName: "FoundSAPAfterSelectedAwaitingSAP", customPropertyName: "Found SAP After Selected Awaiting SAP", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 90, labelName: "This Supporting Record has now been linked to the parent contract", searchClauseName: "SupportingLinkedParent", customPropertyName: "Supporting Linked Parent", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 91, labelName: "Following metadata has been corrected according to contract", searchClauseName: "FollowingMetadataCorrected", customPropertyName: "Following Metadata Corrected", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 92, labelName: "Following Metadata Corrected Text", searchClauseName: "FollowingMetadataCorrectedText", customPropertyName: "Following Metadata Corrected Text",  IsCustomerFiled: true, FieldType: "text", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,maxlength: 1000,placeholder:"Field must be completed"},
            { filedId: 93, labelName: "Following metadata has been corrected by following the parent record", searchClauseName: "FollowingMetadataCorrectedParent", customPropertyName: "Following Metadata Corrected Parent", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 94, labelName: "Following Metadata Corrected Parent Text", searchClauseName: "FollowingMetadataCorrectedParentText", customPropertyName: "Following Metadata Corrected Parent Text",  IsCustomerFiled: true, FieldType: "text", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,maxlength: 1000,placeholder:"Field must be completed"},
            { filedId: 95, labelName: "Other", searchClauseName: "ApproveOther", customPropertyName: "Approve Other", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 96, labelName: "Approve Other Text", searchClauseName: "ApproveOtherText", customPropertyName: "Approve Other Text", IsCustomerFiled: true, FieldType: "text", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,maxlength: 1000,placeholder:"Field must be completed"},
           //Terminate free box
           { filedId: 97, labelName: "Reason for Termination", searchClauseName: "terminationFreeTextBox", customPropertyName: "terminationFreeTextBox", IsCustomerFiled: true, FieldType: "text", IsDisabled: false, IsRequired: true, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: true ,selectTip:false,maxlength: 500,placeholder:"Field must be completed"},
           { filedId: 98, labelName: "Only Notify", searchClauseName: "OnlyNotify", customPropertyName: "OnlyNotify", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: false, value: false, isValidation: true, isShow: true, isRead: false  ,toolTip: true ,selectTip:false, information: "Notification will not be sent to Document Owner."},
           
            { filedId: 99, labelName: "Signature Type", searchClauseName: "SignatureType", customPropertyName: "SignatureType", IsCustomerFiled: true, FieldType: "singleSelect", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false, information:"Is the Agreement using e-signature or written signature?"},
            { filedId: 100, labelName: "Other E-signature", searchClauseName: "ESignatureOtherText", customPropertyName: "E signature Other Text", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,placeholder:"",maxlength:255},
            { filedId: 101, labelName: "Primary Client (If applicable)", searchClauseName: "PrimaryClient", customPropertyName: "Primary Client (If applicable)", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"",maxlength:255, information:"Select the main Client related to this supplier. If there are other related Clients, please add these to the Secondary Client(s) field."},
            { filedId: 102, labelName: "Secondary Client(s)", searchClauseName: "ClientName", customPropertyName: "Secondary Client(s)", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"",maxlength:255},
            { filedId: 103, labelName: "MMD Agreement ID", searchClauseName: "MMDAgreementID", customPropertyName: "MMD Agreement ID", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: false, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"",maxlength:25},        
            { filedId: 104, labelName: "Company Description", searchClauseName: "CompanyDescription", customPropertyName: "Company Description", IsCustomerFiled: true, FieldType: "string", IsDisabled: true, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false, oldValue: "", toolTip: false, selectTip: false, placeholder: "Field must be completed" },

            // adding new field here but don't forget to add it in configurationIDs.ts file
            { filedId: 105, labelName: "Renewed/Reviewed", searchClauseName: "RenewedReviewed", customPropertyName: "RenewedReviewed", IsCustomerFiled: true, FieldType: "boolean", IsDisabled: false, IsRequired: true,value: false, isValidation: true, isShow: true,isRead: false,toolTip: true,selectTip: false,information: "Please select the box if you have reviewed and/or renewed the agreement. Please consult the Legal Department to ensure that all terms are up to date."},
            { filedId: 106, labelName: "Agreement Spend Type", searchClauseName: "AgreementSpendType", customPropertyName: "Agreement Spend Type", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false,value: "", isValidation: true, isShow: true,isRead: false,oldValue:"" ,toolTip: false,selectTip: false,information: "Spend related to Policy 931. To be completed by admin team only."},
            { filedId: 107, labelName: "Legacy Tool", searchClauseName: "LegacyTool", customPropertyName: "Legacy Tool", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 108, labelName: "Cloud Services", searchClauseName: "CloudServicesInformation", customPropertyName: "Cloud Services information", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false,information:"Is data stored in a cloud?"},
            { filedId: 109, labelName: "Which Country/Location?", searchClauseName: "WhichCountryLocation", customPropertyName: "Which Country Location", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false},
            { filedId: 110, labelName: "Termination Terms", searchClauseName: "TerminationTerms", customPropertyName: "Termination Terms", IsCustomerFiled: true, FieldType: "string", IsDisabled: false, IsRequired: false, value: "", isValidation: true, isShow: true, isRead: false ,oldValue:"" ,toolTip: false ,selectTip:false ,placeholder:"Field must be completed",maxlength: 10000},
        ];
        var filed = null;
        if (!isNaN(filedId)) {
            HPEFieldsOnForm.forEach(res => {
                if (res.filedId == filedId) {
                    filed = res;
                }
            });
        } else {
            filed = [];
            HPEFieldsOnForm.forEach(res => {
                filedId.forEach(id => {
                    if (res.filedId == id) {
                        filed.push(res);
                    }
                });
            });
        }
        return filed;
    }

    setFormDataToJson(jsonList: any) {
        var json;
        var jsonStr = "{";
        jsonList.forEach(res => {
            var itemstr;
            if (!res.IsCustomerFiled) {
                itemstr = "\"" + this.replaceChar(res.labelName) + "\":" + JSON.stringify(res)
            } else {
                itemstr = "\"" + res.searchClauseName + "\":" + JSON.stringify(res)
            }
            if (jsonStr == "{") {
                jsonStr = jsonStr + itemstr;
            } else {
                jsonStr = jsonStr + "," + itemstr;
            }
        });
        jsonStr = jsonStr + "}";
        json = JSON.parse(jsonStr);
        return json
    }

    // reset formdata to default value
    multiResetFormdataValue(formdata: any, IDs: any) {
        var fieldNames = this.getFormdataFieldNames(IDs);
        fieldNames.forEach(res => {
            formdata[res].value = "";
            if(formdata[res].customPropertyName=="Other Agreement Type"){
                formdata[res].isShow=false;
                formdata[res].IsRequired=false;
            }
            if(formdata[res].customPropertyName=="Other Language"){
                formdata[res].isShow=false;
                formdata[res].IsRequired=false;
            }
            if(formdata[res].customPropertyName=="Renewal Notice") {
                formdata[res].value = "120";
            }
        });
    }
    
    getFormdataFieldNames(IDs: any) {
        var fieldNames = [];
        var fields = this.getFieldbyFiledId(IDs);
        fields.forEach(res => {
            if (!res.IsCustomerFiled) {
                fieldNames.push(this.replaceChar(res.labelName));
            } else {
                fieldNames.push(res.searchClauseName);
            }
        });
        return fieldNames;
    }

    SetAllDateTimeToDate(formFieldList: any) {
        formFieldList.forEach(res => {
            if (res.IsCustomerFiled && res.FieldType == "date") {
                if(res.value != "" && res.value != null){
                    if(res.value == "Invalid Date") {
                        res.value = null;
                    } else {
                        res.value = this.datepipe.transform(res.value, 'dd-MMM-yyyy')
                    }
                } else{
                    res.value = null;
                }
            }            
        });
    }

    private replaceChar(str: string) {
        return str.replace(" ", "");
    }
}
    function getFieldbyFiledId(filedId: any) {
        throw new Error('Function not implemented.');
    }

